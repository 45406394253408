<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h4>
        Advance Search
      </h4>
    </b-card-header>
    <b-card-body>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Main Title"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.main_title"
              class="w-100"
              placeholder="Enter Main title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Sub Title"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.sub_title"
              class="w-100"
              placeholder="Enter Sub title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Project Type"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value['projectType.name']"
              class="w-100"
              placeholder="Enter Project Type"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Keyword(s)"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value['keywords.name']"
              class="w-100"
              placeholder="Enter Keyword"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Plan No"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.plan_no"
              class="w-100"
              placeholder="Enter Plan No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Contract No"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.contract_no"
              class="w-100"
              placeholder="Enter Contract No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="State"
            label-cols-md="4"
          >
            <v-select
              :value="value.state"
              :options="[]"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:filter.state', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="District"
            label-cols-md="4"
          >
            <v-select
              :value="value.district"
              :options="[]"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:filter.district', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="auto"
          class="ml-md-auto pr-md-0"
        >
          <b-button
            class="w-100"
            variant="outline-primary"
            @click="onReset"
          >
            Reset
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <b-button
            class="w-100 my-1 my-sm-1 my-md-0"
            variant="primary"
            @click="onSubmit"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
      <b-row />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultValue: {
        main_title: null,
        sub_title: null,
        'projectType.name': null,
        'keywords.name': null,
        plan_no: null,
        contract_no: null,
        state: null,
        district: null,
      },
      value: {},
    }
  },
  mounted() {
    this.value = {
      ...this.defaultValue,
      ...this.filter,
    }
  },
  methods: {
    onSubmit() {
      this.$emit('search', this.value)
    },
    onReset() {
      this.value = { ...this.defaultValue }
      this.$emit('search', {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

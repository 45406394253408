<template>
  <div>
    <audit-list-filters
      v-if="useAdvanceSearch"
      :filter="searchAdvance"
      @search="onSearch"
    />

    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <v-select
              v-model="eventsFilter"
              :options="eventsOptions"
              class="audit-filter-select mr-1"
              placeholder="All Events"
              multiple
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>

            <b-button
              v-if="$ability.can('view', 'audit')"
              variant="primary"
              class="ml-50"
              :disabled="isExporting"
              @click="exportAudits()"
            >
              <b-spinner
                v-if="isExporting"
                small
                type="grow"
              />
              <feather-icon
                v-else
                icon="UploadIcon"
                class="mr-50"
              />
              {{ isExporting ? 'exporting...' : 'Export' }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="!useAdvanceSearch"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-form-checkbox
                v-if="false"
                v-model="useAdvanceSearch"
                class="d-inline-block"
              >
                Advance Search
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAuditListTable"
        :small="true"
        :striped="false"
        :bordered="false"
        :borderless="false"
        :hover="true"
        sticky-header="65vh"
        :items="fetchAudits"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="audits-table position-relative"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner />
            loading...
          </div>
        </template>

        <template #head(actions)>
          <feather-icon
            icon="MenuIcon"
            class="mx-auto"
          />
        </template>

        <template #row-details="row">
          <b-card
            border-variant="secondary"
            class="mt-0 mb-0 shadow"
            :title="`Audit Detail: #${ row.item.hashid }`"
          >
            <b-card-body>
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Date/Time : </strong>{{ row.item.created_at }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>User: </strong>
                  <span v-html="formatData(get(row, 'item.user.name'))" />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Module: </strong>
                  <span v-html="formatData(get(row, 'item.recordable_type'))" />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Event: </strong>
                  <span v-html="formatData(get(row, 'item.event'))" />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>IP Address: </strong>
                  <span v-html="formatData(get(row, 'item.ip_address'))" />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>URL: </strong>
                  <span v-html="formatData(get(row, 'item.url'))" />
                </b-col>
                <b-col
                  md="12"
                  class="mb-1"
                >
                  <strong>User Agent: </strong>
                  <span v-html="formatData(get(row, 'item.user_agent'))" />
                </b-col>
                <b-col
                  md="6"
                  class="mb-1"
                >
                  <strong>Properties: </strong>
                  <json-viewer
                    boxed
                    :value="row.item.properties"
                  />
                </b-col>
                <b-col
                  md="6"
                  class="mb-1"
                >
                  <strong>Changes: </strong>
                  <json-viewer
                    boxed
                    :value="row.item.modified"
                  />
                </b-col>
              </b-row>
            </b-card-body>
            <div class="text-right">
              <b-button-group>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="row.toggleDetails"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Close</span>
                </b-button>
              </b-button-group>
            </div>
          </b-card>
        </template>

        <template #cell(hashid)="data">
          <div class="text-nowrap">
            <b-link
              class="font-weight-bold"
              @click="data.toggleDetails"
            >
              <span
                :id="`audit-row-${data.item.id}`"
                class="align-text-top text-capitalize"
              >#{{ data.item.hashid }}</span>
              <b-tooltip
                :target="`audit-row-${data.item.id}`"
                :title="data.detailsShowing ? 'Click to hide details' : 'Click to show details'"
                triggers="hover"
              />
            </b-link>
          </div>
        </template>

        <template #cell(users|users.name)="data">
          <b-badge
            v-if="!data.item.user && data.item.event != 'login'"
            variant="light-secondary"
            size="sm"
          >
            system
          </b-badge>
          <b-link
            v-else-if="data.item.event == 'login'"
            :to="{ name: 'user-edit', params: { hashid: get(data, 'item.recordable.hashid') } }"
          >
            {{ get(data, 'item.recordable.name') }}
          </b-link>
          <b-link
            v-else
            :to="{ name: 'user-edit', params: { hashid: get(data, 'item.user.hashid') } }"
          >
            {{ get(data, 'item.user.name') }}
          </b-link>
        </template>

        <template #cell(event)="data">
          <b-badge
            :variant="`light-${resolveAuditEventVariantAndIcon(data.item.event).variant}`"
          >
            <feather-icon
              :id="`audit-row-${data.item.id}`"
              :icon="resolveAuditEventVariantAndIcon(data.item.event).icon"
              :class="`text-${resolveAuditEventVariantAndIcon(data.item.event).variant}`"
            />
            {{ data.item.event.toUpperCase() || 'NULL' }}
          </b-badge>
        </template>

        <template #cell(modified)="data">
          <small class="json-text">{{ data.value }}</small>
          <!-- json-viewer :expand-depth="1" :value="data.value" / -->
        </template>

        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`audit-row-${data.item.id}-preview-icon`"
              :icon="data.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
              size="16"
              class="mx-1"
              @click="data.toggleDetails"
            />
            <b-tooltip
              :title="data.detailsShowing ? 'Hide details' : 'Show details'"
              :target="`audit-row-${data.item.id}-preview-icon`"
              triggers="hover"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAudits"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination, BTooltip, BFormCheckbox,
  BSpinner, BButtonGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
import { get } from 'lodash'
import JsonViewer from 'vue-json-viewer'
import { httpGetFile } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import useAuditList from './useAuditList'
import auditStoreModule from '../auditStoreModule'
import AuditListFilters from './AuditListFilters.vue'

import 'vue-json-viewer/style.css'

export default {
  components: {
    AuditListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BSpinner,

    vSelect,
    JsonViewer,
  },
  setup() {
    const toast = useToast()

    const AUDIT_STORE_MODULE_NAME = 'app-audit'

    // Register module
    if (!store.hasModule(AUDIT_STORE_MODULE_NAME)) store.registerModule(AUDIT_STORE_MODULE_NAME, auditStoreModule)

    onMounted(() => {
      let thElm
      let startOffset

      Array.prototype.forEach.call(
        document.querySelectorAll('table th'),
        th => {
          // eslint-disable-next-line no-param-reassign
          th.style.position = 'relative'

          const grip = document.createElement('div')
          grip.innerHTML = '&nbsp;'
          grip.style.top = '0'
          grip.style.right = '0'
          grip.style.bottom = '0'
          grip.style.width = '5px'
          grip.style.position = 'absolute'
          grip.style.cursor = 'col-resize'
          grip.addEventListener('mousedown', e => {
            thElm = th
            startOffset = th.offsetWidth - e.pageX
          })

          th.appendChild(grip)
        },
      )

      document.addEventListener('mousemove', e => {
        if (thElm) {
          thElm.style.width = `${startOffset + e.pageX}px`
        }
      })

      document.addEventListener('mouseup', () => {
        thElm = undefined
      })
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDIT_STORE_MODULE_NAME)) store.unregisterModule(AUDIT_STORE_MODULE_NAME)
    })

    const {
      getFetchPayload,
      fetchAudits,
      eventsOptions,
      tableColumns,
      perPage,
      currentPage,
      totalAudits,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchAdvance,
      sortBy,
      isSortDirDesc,
      refAuditListTable,

      eventsFilter,
      useAdvanceSearch,

      refetchData,

      resolveAuditEventVariantAndIcon,
      resolveClientAvatarVariant,
    } = useAuditList()

    const onSearch = filter => {
      searchAdvance.value = filter
      refetchData()
    }

    const formatData = (data, emptyValue = null) => data ?? `<b-badge variant="light-secondary">${emptyValue ?? 'null'}</b-badge>`

    const isExporting = ref(false)

    const exportAudits = () => {
      isExporting.value = true
      store
        .dispatch('app-audit/fetchAudits', getFetchPayload(1))
        .then(response => {
          httpGetFile({
            blob: response.data,
            fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileName: 'eplan-audit-logs-export.xlsx',
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting Audit Logs',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          isExporting.value = false
        })
    }

    return {
      fetchAudits,
      tableColumns,
      perPage,
      currentPage,
      totalAudits,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchAdvance,
      sortBy,
      isSortDirDesc,
      refAuditListTable,

      eventsFilter,
      useAdvanceSearch,

      refetchData,

      isExporting,
      exportAudits,

      eventsOptions,

      avatarText,
      resolveAuditEventVariantAndIcon,
      resolveClientAvatarVariant,

      onSearch,
      formatData,
      get,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.audits-table {
  ::v-deep td {
    font-size: 0.85rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  ::v-deep th {
    font-size: 0.9rem;
  }
}

.audit-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    min-width: 70px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.json-text {
  font-family: Consolas, Menlo, Courier, monospace;
  font-stretch: condensed;
}
</style>

import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAudits(ctx, { params, config = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/audit', { params, ...config })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAudit(ctx, { hashid, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/audit/${hashid}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAuditList() {
  // Use toast
  const toast = useToast()

  const refAuditListTable = ref(null)

  const eventsOptions = [
    'login',
    'logout',
    'created',
    'updated',
    'deleted',
    'synced',
  ]

  // Table Handlers
  const tableColumns = [
    {
      key: 'hashid', label: '#', thClass: 'text-left', tdClass: 'text-left', sortable: true,
    },
    { key: 'created_at', label: 'Date/Time', sortable: true },
    { key: 'users|users.name', label: 'User', sortable: true },
    { key: 'recordable_type', label: 'Module', sortable: true },
    { key: 'event', label: 'Event', sortable: true },
    { key: 'modified', label: 'Changes', sortable: false },
    { key: 'ip_address', label: 'IP', sortable: true },
    { key: 'actions', thClass: 'text-center', tdClass: 'text-center' },
  ]
  const perPage = ref(25)
  const totalAudits = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const searchAdvance = ref({})
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const eventsFilter = ref([])
  const useAdvanceSearch = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refAuditListTable.value ? refAuditListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAudits.value,
    }
  })

  const refetchData = () => {
    refAuditListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, eventsFilter, useAdvanceSearch], () => {
    refetchData()
  }, { deep: true })

  const getFetchPayload = (isExport = 0) => {
    // quick search or advance search
    let search = ''
    if (useAdvanceSearch.value) {
      search = Object.entries(searchAdvance.value || {})
        .filter(([key, value]) => ((Array.isArray(value))
          ? value.length > 0
          : value))
        .map(([key, value]) => (Array.isArray(value)
          ? `${key}:${value.join(',')}`
          : `${key}:${value}`))
      search = search.join(';')
    } else {
      search = searchQuery.value
    }

    return {
      params: {
        with: 'recordable;user',
        search,
        search_join: useAdvanceSearch.value ? 'and' : 'or',
        per_page: perPage.value,
        page: currentPage.value,
        order_by: sortBy.value,
        sort_dir: isSortDirDesc.value ? 'desc' : 'asc',
        events: eventsFilter.value,
        export: isExport ? 1 : 0,
      },
      config: isExport ? { responseType: 'blob' } : {},
    }
  }

  const fetchAudits = (ctx, callback) => {
    store
      .dispatch('app-audit/fetchAudits', getFetchPayload())
      .then(response => {
        const audits = response.data.payload.data

        callback(audits)
        totalAudits.value = response.data.payload.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching audit list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAuditEventVariantAndIcon = event => {
    if (event === 'login') return { variant: 'primary', icon: 'UnlockIcon' }
    if (event === 'logout') return { variant: 'secondary', icon: 'LockIcon' }
    if (event === 'created') return { variant: 'success', icon: 'PlusIcon' }
    if (event === 'updated') return { variant: 'warning', icon: 'Edit3Icon' }
    if (event === 'deleted') return { variant: 'danger', icon: 'TrashIcon' }
    return { variant: 'secondary', icon: 'InfoIcon' }
  }

  return {
    getFetchPayload,
    fetchAudits,
    eventsOptions,
    tableColumns,
    perPage,
    currentPage,
    totalAudits,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchAdvance,
    sortBy,
    isSortDirDesc,
    refAuditListTable,

    eventsFilter,
    useAdvanceSearch,

    resolveAuditEventVariantAndIcon,
    refetchData,
  }
}
